<template>
    <div class="w-11/12 md:w-6/12 lg:w-3/12 py-4 px-2 min-w-[266px] hidden">
        <div class="flex-1 bg-white rounded-2lg p-5 lg:p-4 border-2 border-bordergray">
            <div class="relative w-full">
                <div class="absolute right-0 flex items-center">
                    <a class="mr-6" href="">
                        <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 20.981 26.525">
                            <g id="share-outline" transform="translate(1.25 1.25)" opacity="0.5">
                                <path id="Path_77" data-name="Path 77" d="M20.61,13.5h2.31a2.31,2.31,0,0,1,2.31,2.31V26.9a2.31,2.31,0,0,1-2.31,2.31H9.06A2.31,2.31,0,0,1,6.75,26.9V15.81A2.31,2.31,0,0,1,9.06,13.5h2.31" transform="translate(-6.75 -5.184)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                <path id="Path_78" data-name="Path 78" d="M21.615,8,17,3.375,12.375,8" transform="translate(-7.755 -3.375)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                                <path id="Path_79" data-name="Path 79" d="M18,19.141V3.375" transform="translate(-8.76 -3.375)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
                            </g>
                        </svg>
                    </a>
                    <svg width="20" height="20" viewBox="0 0 28.164 23.242" class="cursor-pointer">
                        <path
                            id="_23"
                            data-name="23"
                            d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                            transform="translate(-2.052 -3.77)"
                            fill="#231f20"
                            opacity="0.5"
                        />
                        <path id="Path_50" data-name="Path 50" d="M2605.027,1375.483l3.363-2.4,5.89.491,4.247,2.575v4.546l-.806,4.555-4.806,4.015-7.888,2.158-4.967-3.347-4.8-6.758-.3-4.122,1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#231f20" opacity="0" />
                    </svg>
                </div>
            </div>
            <div>
                <Link href="/urun"><img src="../../../images/b7dcc472339c0ec4c42c0ed542d25a@2x.png" alt="" /></Link>
            </div>
            <div class="text-center"><a href="" class="text-xs text-brand-color font-semibold">Go Pro</a></div>
            <div class="text-sm font-semibold mt-3">GoPro HERO 9 Black (2020)</div>
            <div class="text-xs mt-1 text-kbgray">20MP HDR, Gelişmiş Aktif Gürültü Önleme, 5K Video, Su Geçirmez.</div>
            <div class="mt-3">
                <div class="flex text-xs text-center cursor-pointer align-middle">
                    <div class="border-y-2 transition delay-150 w-3/12 px-1.5 md:px-2 lg:px-1 py-2 border-l-2 align-middle relative" :class="[selectedMonth == 1 ? selectedClass : 'rounded-l-xl']" @click="selectMonth(1)">1 Ay</div>
                    <div class="border-y-2 transition delay-150 w-3/12 px-1.5 md:px-2 lg:px-1 py-2 align-middle relative" :class="[selectedMonth == 3 ? selectedClass : '']" @click="selectMonth(3)">3 Ay</div>
                    <div class="border-y-2 transition delay-150 w-3/12 px-1.5 md:px-2 lg:px-1 py-2 align-middle relative" :class="[selectedMonth == 6 ? selectedClass : '']" @click="selectMonth(6)">6 Ay</div>
                    <div class="border-y-2 transition delay-150 w-3/12 px-1.5 md:px-2 lg:px-1 py-2 align-middle relative" :class="[selectedMonth == 9 ? selectedClass : '']" @click="selectMonth(9)">
                        9 Ay
                        <div class="absolute -bottom-2 z-10 left-2">
                            <div class="bg-kbgreen font-bold text-2xs text-white rounded-full px-1 self-center leading-tight">Avantajlı</div>
                        </div>
                    </div>
                    <div class="border-y-2 transition delay-150 border-r-2 w-3/12 px-1.5 md:px-2 lg:px-1 py-2 align-middle relative" :class="[selectedMonth == 12 ? selectedClass : 'rounded-r-xl']" @click="selectMonth(12)">12 Ay</div>
                </div>
            </div>
            <div class="flex mt-4 lg:mt-2">
                <span class="font-semibold text-2xl">540 TL /</span>
                <span class="text-2xs self-center ml-2"> Ay fiyatından kirala.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    data() {
        return {
            selectedMonth: 1,
            selectedClass: "rounded-full -m-2 pt-4",
        };
    },
    components: {
        Link,
    },
    methods: {
        selectMonth(month) {
            this.selectedMonth = month;
        },
    },
};
</script>

<style scoped></style>
